<template>
  <div class="login-container">
    <div v-if="!$auth.isAuthenticated" class="login-btn">
      <label> Parabooking BMS </label>
      <b-button @click="login" variant="dark" size="bg" class=""
        ><i class="mdi mdi-login"></i> Log In</b-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  methods: {
    // Log the user in
    login() {
      this.$store.commit("setCurrentRole", "");
      this.$auth.loginWithRedirect();
    },
  },
};
</script>
<style lang="scss" scoped>
.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    circle,
    rgba(11, 208, 238, 1) 14%,
    rgba(11, 156, 235, 1) 100%,
    rgba(255, 255, 255, 1) 100%
  );

  .login-btn {
    label {
      font-size: 1.5rem;
      display: block;
    }
  }
}
</style>
